import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import TitleMusician from 'src/components/svg/TitleMusician'
import TitleEssayist from 'src/components/svg/TitleEssayist'

const Titles: React.FC<{ horizontal?: boolean }> = ({ horizontal }) => {
  const { i18n } = useTranslation()
  const language = i18n.language.split('-')[0] as 'en' | 'zh' | 'ja'
  return horizontal ? (
    <div>
      <TitleMusician horizontal language={language} className="mb-2" />
      <TitleEssayist horizontal language={language} />
    </div>
  ) : (
    <div className="flex justify-end">
      <TitleEssayist
        language={language}
        className={clsx('flex-initial mr-5 transform', {
          'translate-y-1': language === 'ja',
        })}
      />
      <TitleMusician language={language} className="flex-initial" />
    </div>
  )
}

export default Titles
