import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Me: React.FCX = props => {
  const data = useStaticQuery(graphql`
    query {
      meImage: file(relativePath: { eq: "me.jpg" }) {
        childImageSharp {
          fixed(width: 240, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  if (!data?.meImage?.childImageSharp?.fixed) {
    return null
  }

  return <Img fixed={data.meImage.childImageSharp.fixed} {...props} />
}

export default Me
