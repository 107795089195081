import React from 'react'
import { PageProps, graphql } from 'gatsby'
import { InfoQuery } from 'types/graphql-types'
import Layout from 'src/components/Layout'
import Me from 'src/components/Me'
import SEO from 'src/components/Seo'
import Titles from 'src/components/Titles'
import Article from 'src/components/Article'

const Index: React.FC<PageProps<InfoQuery>> = ({ data }) => (
  <>
    <SEO />
    <Layout>
      <div className="mt-36 block md:hidden">
        <Titles horizontal />
      </div>
      <div className="hidden md:flex">
        <Me />
        <div className="flex-grow">
          <Titles />
        </div>
      </div>
      <Article className="pt-28 md:pt-16">
        <div
          dangerouslySetInnerHTML={{
            __html: data.info?.data?.body?.html!,
          }}
        />
      </Article>
      <div className="block md:hidden">
        <Me className="mt-10" />
      </div>
    </Layout>
  </>
)

export const query = graphql`
  query Info($language: String!) {
    info: prismicInfo(lang: { eq: $language }) {
      lang
      data {
        body {
          html
        }
      }
    }
  }
`

export default Index
