import React from 'react'
import clsx from 'clsx'

const Article: React.FCX = ({ className, ...props }) => (
  <article
    className={clsx(
      'prose max-w-none leading-loose tracking-wider prose-sm md:prose md:max-w-none',
      className
    )}
    {...props}
  />
)

export default Article
